import './App.css';
import Header from './component/Header';
import Sidebar from './component/Sidebar';
import Footer from './component/Footer';
import Login from './component/Login';
import Dashboard from './component/Dashboard';
//import Studselect from './component/Stuselect';

import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div>
      <Header />
      <Sidebar />
      <Routes>
        <Route path='Login' element={<Login />}></Route>
          
        <Route path='/' element={<Dashboard />}></Route>
       
      </Routes>

      <Footer />
    </div>
  );
}

export default App;

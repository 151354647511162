import  React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Dashboard = () => {

    const navigate = useNavigate();
  
    const Stumobile = localStorage.getItem("mobile");
 
    const [stu,setStu]= useState([]);
    const [stufee,setstufee]= useState('');
    const [FeeSub,setFeesub]= useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [vis,setVis]= useState(true);
    const [clickedIndex, setClickedIndex] = useState(null);  
   

    const url = 'https://demoapi.uniservedata.in/api/student?Ph='+Stumobile;
   

    useEffect(() => {   
         moveto();           
         showContent(url);   
         
    },[clickedIndex]);

    const moveto =()=>{
        const loggedInUser = localStorage.getItem("authenticated");
        if(!loggedInUser)
        {
            navigate("Login");
        }
    }
    
   const showFee = async(feeurl,studata)=>{   

      const res = await fetch(feeurl);
      const data = await res.json();  
      setstufee(data.Obj_data); 

      const SubmitFee= 'https://demoapi.uniservedata.in/api/Fee' ;
        FeeSubmitted(SubmitFee,studata);

  }

    const showContent = async (url) => {     
     
        const res = await fetch(url);
        let data = await res.json();        
         
        if(data.Stu_data.length >1 )   
        {      
          setVis(false);   
          setStu(data.Stu_data);
            if(clickedIndex != null)     
            {
              setVis(true);  
              setStu(data.Stu_data[clickedIndex]);           

              const stuclass= data.Stu_data[[clickedIndex]].Class;
              const characters = stuclass.split('');      
              
              const feeurl = 'https://demoapi.uniservedata.in/api/Feestr?Class='+ characters[0] ;
              if(vis == true )
              showFee(feeurl,data);  
            }
           
          }
          else{
            setStu(data.Stu_data[0]);           

            const stuclass= data.Stu_data[0].Class;
            const characters = stuclass.split('');      
            
            const feeurl = 'https://demoapi.uniservedata.in/api/Feestr?Class='+ characters[0] ;
            if(vis == true )
            showFee(feeurl,data);  
          }
      }

      const FeeSubmitted =async(url,studata)=>{
      
       
        let Stuid;
        if(clickedIndex != null)
        {          
          Stuid= studata.Stu_data[clickedIndex]._id         
        }
        else{
          Stuid=studata.Stu_data[0]._id;
        } 

        const res= await fetch(url,{
          
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },            
      
        body: JSON.stringify({          
          StuId: Stuid,        
          Year: "2023-2024",
          PayStatus:"Paid",
         
      }),
      
      });

      const data= await res.json();
     
      setFeesub(data.Obj_data);


//--------------------logic to get month from table A------
        for(var a=0;a<data.Obj_data.length;a++){    
            const months= data.Obj_data[a].Month.split(',');
            (months).forEach(i => {
              removeRowsByColumnValue(2, i);
            });
        }
      }

    
      const handleCheckboxChange = (item) => {
        setSelectedValues((prevValues) =>
          prevValues.some((selectedItem) => selectedItem._id === item._id)
            ? prevValues.filter((selectedItem) => selectedItem._id !== item._id)
            : [...prevValues, item]
        );
        
      };


      const Getinfofromheckbox=()=>{
        let month="";
        let amo=0;
        selectedValues.forEach(item => {
          if(amo ==0)          
            month=item.Month;
          else          
          month=month+","+item.Month;
          
          amo=amo+Number (item.Amount);          
        })
       // console.log(month+"-"+amo.toString());
        return(month+"-"+amo.toString());
      }


      const createOrder = async (stu) => {

        toast.success("Payment window is loading Please wait !!!", {
          position: "top-right",
          theme: "colored",
        });


        const items=  Getinfofromheckbox().split('-');

         try {
          const resp = await fetch('https://demoapi.uniservedata.in/api/payment/create-order', {

            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },            
          
            body: JSON.stringify({          
              StuId: stu._id,
              Ph: stu.Ph,
              Month:items[0],
              Amount:items[1],
              Year: selectedValues[0].Year,
              Class:stu.Class,
             
          }),
          
          });
    
       const data= await resp.json();
          console.log(data);
         
          //-------------------------------

          const options = {
            key: 'rzp_test_1iQFmaJJXfdq9E',
            amount: Number(items[1]*100), 
            currency: 'INR',
            name: 'SBVM School Hapur',
            email: stu.Email,
            contact: stu.Ph,
            description: items[0],
            order_id: data.id, // replace with the actual order ID
            handler:  function (response) {
              // handle the payment success             

                const res =  fetch('https://demoapi.uniservedata.in/api/payment/capture-payment', {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                  },
      
                body: JSON.stringify({          
               
                  paymentId: response.razorpay_payment_id,
                  order_id: data.id,
                  amount: items[1], 
      
              }),
                  
                });
            
               // setPaymentId(response.data.paymentId);
               
                console.log('Payment captured successfully:', response);
              
              

            },
            prefill: {
              name: stu.Name,
              email: stu.Email,
              contact: stu.Ph,
            },
            notes: {
              address: stu.Address,
            },
            theme: {
              color: '#F37254',
            },
          };      
      

          const paymentObject = new window.Razorpay(options)
          paymentObject.open();

          //----------------------------

        } catch (error) {
          console.error('Error creating order:', error);
        }   

       
      };


      function removeRowsByColumnValue(columnIndex, matchValue) {
            
        var table = document.getElementById("fees"); // Replace "yourTable" with the actual ID of your table
        var rows = table.getElementsByTagName("tr");
       
        for (var i = rows.length - 1; i >= 0; i--) {          
          if (rows[i].cells[columnIndex].textContent.trim() === matchValue) {
            table.deleteRow(i);
          }
        }
      }

      const getIndex = (index) => {       
        setClickedIndex(index);
        setVis(true);         
      };
      

      //----------***************

        return (
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>

                <section class="section dashboard">
                {
              vis && 
              <>
                < div className="row col-lg-12 col-md-12 ">
              
          <div className="row col-lg-12 col-md-12 ">
        
           
            <div className="card">
              <div className="card-body table-container">
                <h5 className="card-title">Student Data</h5>


                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Address</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Class</th>
                      <th scope="col">Class Teacher</th>
                      <th scope="col">Father</th>
                      <th scope="col">Mother</th>
                     {/*  <th scope="col">Edit</th> */}
                     
                    </tr>
                  </thead>
                  <tbody>
                     {

                     stu &&  (
                        <tr key={stu._id}>
                     
                          <td>{stu.Name} </td>
                          <td>{stu.Email}</td>
                          <td>{stu.Address} </td>
                          <td>{stu.Ph}</td>
                          <td>{stu.Class} </td>
                          <td>{stu.ClassTeacher}</td>
                          <td>{stu.FatherName} </td>
                          <td>{stu.Mothername}</td>

                        {/*   <td><Link class="btn btn-primary w-100" to={`../UpdateStudent/${stu._id}`} >Update</Link></td> */}
                        </tr>
                      )}
 
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          </div>


         <div className="row col-lg-12 col-md-12 ">
        
         < div className="row col-lg-6 col-md-6 ">

          <div className="card">
              <div className="card-body table-container">
                <h5 className="card-title">Student Fee Online Submitted </h5>


                <table className="table table-hover ">
                  <thead>
                    <tr>
                      
                      
                      <th scope="col">#</th>
                      <th scope="col">Month</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                      <th scope="col">Transection ID</th>  
                      <th scope="col">Recipt Number</th>                   
                     
                    </tr>
                  </thead>
                  <tbody>
                  {   

                   vis && FeeSub && FeeSub.map((fee, i) => (
                        <tr key={fee._id}>
                        
                          <th scope="row">{i + 1}</th>                 
                          <td>{fee.Month}</td>
                          <td>{fee.Amount}</td>                        
                          <td>{fee.Date.split('T')[0]}</td>
                          <td>{fee.Tran}</td> 
                          <td>{fee.Recipt}</td>
                        
                        </tr>
                      ))}
 
                  </tbody>
                </table>
                {/*  <button class="btn btn-primary w-100" type="submit" onClick={()=>createOrder(stu)}>Pay  {Getinfofromheckbox().split('-')[1]}</button>   */}
              </div>             
            </div>
            </div>
          

         

          
            < div className="row col-lg-5 col-md-7 " style={{ "margin-left": "5px"}}>

        <div className="card">
            <div className="card-body table-container">
              <h5 className="card-title">Student Fee Data</h5>


              <table className="table table-hover" id="fees">
                <thead>
                  <tr>
                    
                    <th scope="col">Select Fee Month</th>
                    <th scope="col">#</th>
                    <th scope="col">Month</th>
                    <th scope="col">Amount</th>
                  {/*   <th scope="col">Date</th>
                    <th scope="col">Transection ID</th>  
                    <th scope="col">Pay</th>    */}                
                   
                  </tr>
                </thead>
                <tbody>
                {   

              vis &&  stufee && stufee.map((fee, i) => (
                      <tr key={fee._id}>
                        <th>
                        <input type="checkbox"  id={fee._id}  value={fee._id}
          checked={selectedValues.some((selectedItem) => selectedItem._id === fee._id)}
          onChange={() => handleCheckboxChange(fee)}
        />
    
                        </th>
                        <th scope="row">{i + 1}</th>                 
                        <td>{fee.Month}</td>
                        <td>{fee.Amount}</td>                        
                      </tr>
                    ))}

                </tbody>
              </table>
               <button class="btn btn-primary w-100" type="submit" onClick={()=>createOrder(stu)}>Pay  {Getinfofromheckbox().split('-')[1]}</button>  
            </div>             
          </div>
          </div>
         



           
              </div></>
}
{
  !vis &&
  <>
  <div className="row col-lg-12 col-md-12 ">


<div className="card">
  <div className="card-body table-container">
    <h5 className="card-title">Student Data</h5>


    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Mobile</th>
          <th scope="col">Class</th>
          <th scope="col">Class Teacher</th>
          <th scope="col">Father</th>
          <th scope="col">Select</th>
         {/*  <th scope="col">Edit</th> */}
         
        </tr>
      </thead>
      <tbody>
         {

          stu && stu.map((item, index) => ( 
            <tr key={item._id}>
         
              <td>{item.Name} </td>        
              <td>{item.Ph}</td>
              <td>{item.Class} </td>
              <td>{item.ClassTeacher}</td>
              <td>{item.FatherName} </td>
              <td>{<button class="btn btn-primary w-100" type="submit" onClick={()=>getIndex(index)}>Select</button>}</td>     
                     
            </tr>
       ))}
        

      </tbody>
    </table>

  </div>
</div>
</div>
  </>
}

                </section>
                <ToastContainer />
            </main>
        );
    }


export default Dashboard;